.password-screen {
  height: 100vh;
  width: 100%;
  background-color: #000;

  .container {
    height: 100%;
    .row {
      height: 100%;
      align-items: center;
      justify-content: center;
      form {
        height: 160px;
        padding: 1rem;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0px 6px 12px #4caf50;
        button {
          border-radius: 20px;
          box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
}
// @media (max-width: 768px) {
.password-screen {
  background-color: #fff;
  .mobileView {
    display: block;
    text-align: center;
    font-family: "DM SANS";
  }
}
// }
// @media (min-width: 769px) {
// .password-screen {
//   background-color: #000;
//   .mobileView {
//     display: none;
//   }
//   .tempForm {
//     display: block;
//   }
// }
// }

// @media screen and (orientation: landscape) and (max-device-width: 1024px) {
// .password-screen {
//   background-color: #fff;
//   .mobileView {
//     display: block;
//     text-align: center;
//     font-family: "DM SANS";
//   }
//   .tempForm {
//     display: none;
//   }
// }
// }
