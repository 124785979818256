@import "~react-image-gallery/styles/scss/image-gallery.scss";

.Activitypage-container {
  font-family: "DM Sans";
  .activityPage-aside-posts {
    font-family: "DM Sans";
    position: fixed;
    // max-width: 30%;
    width: 25%;
    height: 56%;
    padding-bottom: 50px;

    p:nth-of-type(1) {
      border-bottom: 1px solid #c1c1c1;
      padding-bottom: 1rem;
      font-weight: 700;
      letter-spacing: 1px;
      margin-bottom: 1rem;
    }

    .dropdown-outer-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .dropdown-container {
      background-color: white;
      height: 35px;
      width: 48%;
      margin-right: 0;
      border: 1px solid #eceaea;
      box-sizing: border-box;
      border-radius: 4px;

      .dropdown-menu {
        width: 100%;
        max-height: 210px;
        overflow-y: scroll;
        overflow-x: hidden;

        &::-webkit-scrollbar {
          height: 0px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #dcdcdc;
          height: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }

        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }

      .dropdown-options {
        width: 100%;
        max-height: 300px;
        overflow: scroll;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .dropdown-toggle {
        background-color: transparent;
        height: 35px;
        box-shadow: none;
      }

      .dropdown-span {
        padding-top: 6px;
        min-width: 100%;
        max-width: 100%;
      }

      .dropdown-arrow {
        top: 15px;
      }

      .btn-primary.disabled,
      .btn-primary:disabled {
        color: #333;
      }
    }

    .dropdown-charity {
      width: 100%;
      margin-top: 15px;
    }

    .activityPage-pooled-post {
      position: absolute;
      overflow-y: scroll;
      height: 100%;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      margin-top: 20px;
      width: 100%;

      &::-webkit-scrollbar {
        display: none;
      }

      .loading {
        padding: 0;
        // background-color: red;
        display: block;
      }

      .mostpooled-emptydata-text {
        font-weight: 400;
        width: 100%;
        text-align: center;
      }
    }

    .dropdown-btn {
      cursor: pointer;
    }
  }

  // --------- Modal ----------- //

  .image-gallery-icon {
    &:hover {
      color: #fff;
    }
  }

  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 20px;
    width: 20px;

    color: #333;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
  }

  .prev-arrow,
  .next-arrow {
    cursor: pointer;
    color: #fff;
    font-size: 20px;
  }

  .prev-arrow {
    position: fixed;
    top: 50%;
    left: 2%;
  }

  .next-arrow {
    position: fixed;
    top: 50%;
    right: 2%;
  }

  #activity-modal {
    position: fixed;
    overflow: auto;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(63, 61, 61, 0.692);
    z-index: 2000 !important;
    .modal-close-btn {
      cursor: pointer;
      color: #000;
      font-size: 25px;
    }
    .sharingOnMedia-main {
      // position: absolute;
      // bottom: 0px;
      // right: 0px;
    }

    .modal-dialog {
      .modal-content {
        .modal-header {
          .modal-header-container {
            display: flex;
            img {
              width: 56px;
              height: 56px;
              border-radius: 50%;
              -khtml-user-select: none;
              -o-user-select: none;
              -moz-user-select: none;
              -webkit-user-select: none;
              user-select: none;
              object-fit: cover;
              cursor: pointer;
            }
            ul {
              margin-left: 1rem;
              vertical-align: middle;
              .modal-name {
                font-weight: 500;
                margin: 0;
                cursor: pointer;
              }
              .postcontent-duration {
                .timeline {
                  font-size: 12px;
                  color: #757575;
                  font-family: "DM Sans";
                }
              }
            }
          }
        }
        .most-pooled-modal-body {
          padding: 0rem;
          .post-modal-content {
            padding: 1rem;
          }
        }
      }
      .charity-info-container {
        .charity-info {
          display: flex;
          align-items: center;
          background-color: #eef4f9;
          margin: 10px 0;
          padding: 0 30px;
          .charity-logo {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }
          p {
            margin: 0;
          }
          .charity-country {
            font-size: 14px;
          }
        }
      }
    }
  }

  // .display-block {
  //   display: block;
  // }

  // .display-none {
  //   display: none;
  // }

  .most-pooled-item {
    margin-bottom: 20px;
    width: 100%;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.9);
    .most-pooled-item-container {
      display: flex;
      justify-content: space-between;
      background-color: #fff;
      align-items: center;
      padding: 10px;
      width: 100%;

      .most-pooled-main {
        display: flex;
        align-items: center;
      }

      .most-pooled-name {
        font-size: 18px;
        margin: 0;
        margin-left: 10px;
      }
    }

    .most-pooled-profile-pic {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
    }

    .most-pooled-display-picture {
      width: 100%;
      max-height: 186px;
      object-fit: contain;
    }
    .most-pooled-display-video {
      width: 100%;
      max-height: 186px;
      object-fit: contain;
    }
  }

  .modal-container {
    padding: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    .modal-inner-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
    }
    .closeAndReportButton {
      height: 3.5rem;
      .horizontaldots {
        position: absolute;
        top: 2rem;
        right: 0rem;
        cursor: pointer;
      }
      button {
        position: absolute;
        top: 3.4rem;
        right: 0;
        height: 3rem;
        padding: 0px 27px 0px 18px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        background-color: #fff;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
        &:hover {
          background-color: #eef4f9;
          color: #2077b1;
          transform: translateY(1px);
          .social-media-icon {
            path {
              fill: #2077b1;
            }
          }
        }
      }
    }
    // .modal-header-container {
    //   display: flex;
    //   align-items: center;
    //   // margin-bottom: 20px;

    //   .modal-profile-pic {
    //     width: 56px;
    //     height: 56px;
    //     margin-right: 1rem;
    //     border-radius: 50%;
    //     -khtml-user-select: none;
    //     -o-user-select: none;
    //     -moz-user-select: none;
    //     -webkit-user-select: none;
    //     user-select: none;
    //     object-fit: cover;
    //     cursor: pointer;
    //   }

    //   .modal-name {
    //     margin: 0;
    //     cursor: pointer;
    //     // margin-left: 10px;
    //   }
    //   .timeline {
    //     font-size: 12px;
    //     color: #757575;
    //     font-family: "DM Sans";
    //   }
    // }
  }

  .Activitypage-card {
    margin-bottom: 1rem;
    border-radius: 8px;

    .activitypage-header {
      background: #ffffff;
      border-radius: 0.5rem 0.5rem 0 0;
      .contentCreatorName {
        cursor: pointer;
      }
      .timeline {
        font-size: 12px;
        color: #757575;
        font-family: "DM Sans";
      }
    }

    .featured-image {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .activitypage-featured-user {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 125%;
      color: #212121;
    }
    .video-js {
      width: 100%;
    }
    .profile-picture {
      width: 56px;
      height: 56px;
      object-fit: cover;
      cursor: pointer;
    }
    .Activitypage-user {
      font-family: "DM Sans";
      font-style: normal;
      font-size: 20px;
      font-weight: 500;
      padding-left: 1rem;
      color: #212121;
    }
    .card-text {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: #3b3b3b;
      text-align: left;
    }
    .downArrowChat {
      transition: all 0.5s;
      margin-left: 1rem;
    }

    .arrow-animation-up {
      transform: rotate(-180deg);
    }
  }
}

.image-slider-container {
  position: relative;

  .image-count {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: #333;
    width: 45px;
    text-align: center;
    z-index: 1;
    color: #ccc;
    border-radius: 10px;
    font-size: 14px;
  }

  .image-current-count {
    position: relative;
    top: -200px;
    left: 80%;

    background-color: red;
    z-index: 10;
    width: 45px;
  }
  .slick-arrow {
    background-color: transparent;
  }

  .slick-prev {
    left: 20px;
    z-index: 5;
  }

  .slick-next {
    right: 20px;
  }

  .slick-prev:before,
  .slick-next:before {
    // opacity: 0.7;
    color: #fff;
  }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0;
  }
  .slick-slide {
    div {
      background-color: #000;
      .video-brand-logo {
        position: absolute;
        top: 11px;
        left: 10px;
        width: 54px;
      }
      .activity-image {
        object-fit: contain;
        max-height: 485px;
      }
      .Activitypage-contentvideo {
        // background-color: black;
        height: 420px;
        width: 100%;
        display: block;
        margin: 0 auto;
        .video-js .vjs-big-play-button {
          font-size: 3em;
          line-height: 2em;
          height: 2em;
          width: 2em;
          display: block;
          position: absolute;
          top: 10px;
          left: 10px;
          padding: 0;
          cursor: pointer;
          opacity: 1;
          border: 0.06666em solid #fff;
          background-color: #2b333f;
          background-color: rgba(43, 51, 63, 0.7);
          border-radius: 6.3em;
          transition: all 0.4s;
        }
      }
    }
  }
}

.spinner-border {
  margin: 0 auto;
}

.Activitypage-main {
  padding: 1rem 5rem 0 5rem;
}

.activity-page-charity {
  padding: 0.5rem 1rem;
  width: 100%;
  margin: 1rem 0;
  .activity-page-content {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
  }
}

.activity-page-charityName {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #575757;
  margin-bottom: 0;
  padding: 0 10px;
}
.activity-page-charityLocation {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #575757;
  margin-bottom: 0;
  padding: 0 10px;
}
.activitypage-featured-picture {
  height: 40px;
  width: 40px;
}
.activitypage-featured-name {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #212121;
}
.rounded-circle {
  border-radius: 50% !important;
  // padding: 10px 20px;
}
.featured-post {
  width: 100%;
  height: 244px;
  background-color: black;
}
.Activitypage-featured-post {
  width: 241px;
  height: 18px;
  left: 0px;
  top: 0px;
  margin-bottom: 0.5rem;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  /* or 21px */

  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Logo P2 */

  color: #2077b1;
}

.featured-modal-charity {
  margin-top: -1rem;
}
.featured-modal-poolbtn {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  margin: 0 1rem;
  border-radius: 33px;
  margin-bottom: 1rem;
}
.featured-modal-header {
  width: 100%;
  height: 88px;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
}
.featured-header-profile-picture {
  width: 56px;
  height: 56px;
  .featured-header-username {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #212121;
  }
}
.comments-share {
  margin: 1rem;
}
.comment-section {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #3b3b3b;
}
.comment-section-post {
  width: 32px;
  height: 32px;
  margin-right: 0.5rem;
}

.comment-section-post-item {
  // display: "flex";
  // justify-content: "space-around";
  // align-items: "center";
  border-radius: 24px;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  background: #f5f5f5;
  border: 1px solid #ededed;
  box-sizing: border-box;
  // border-radius: 20px;
  form {
    display: inline-block;
    position: relative;
    width: 90%;
  }
  .comment-section-input {
    color: #575757;
    border: 1px solid #f5f5f5;
    background-color: #f5f5f5;
    width: 90%;
    height: 48px;
    &:focus {
      outline: none;
    }
  }
  button {
    position: absolute;
    right: 0;
    top: 13px;
    border: none;
  }
}
.comment-post-btn {
  color: #2077b1;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}
.comment-section-post-comment {
  // height: 70px;
  background: #f5f5f5;
  border: 1px solid #ededed;
  box-sizing: border-box;
  border-radius: 20px;
  // margin: 0 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
}
.commented-name {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 125%;
  color: #3b3b3b;
  margin-left: 8px;
  // margin-bottom: 0.5rem;
}
.comment-section-comments {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 110%;
  // margin-left: 0.5rem;
  color: #575757;
  margin-left: 40px;
  // margin-bottom: 0;
}
.comment-delete-btn {
  position: absolute;
  right: 1.5rem;
}
.comment-section-view-comments {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #3b3b3b;
  margin-left: 1rem;
}
.postcontent-card {
  margin-bottom: 1rem;
}
.report-wrapper {
  font-family: "DM Sans";
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  span {
    cursor: pointer;
  }
}
// .Activitypage-video-parent-overlay {
//   width: 31.4rem;
//   position: relative;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   video {
//       width: 31.4rem;
//       height: 31.4rem;

//       &:focus {
//           outline: none;
//       }
//   }

//   .Activitypage-video-child-overlay {
//     width: 100%;
//     height: 100%;
//     position: absolute;
//     top: 0;
//     left: 0;
//   }
//   .Activitypage-overlay-hidden {
//     display: none;
//   }
//   .Activitypage-video-overlay {
//       height: 100%;
//       background-color: #fff;
//       opacity: 0.8;
//       border-radius: 1rem;

//   }
//   img {
//     position: absolute;
//     top: 13rem;
//     right: 13.7rem;
//   }
// }

// // ================ TABLET ==============
// @media (min-width: 768px) and (max-width: 992px) {
// .Activitypage-video-parent-overlay {
//     width: 31.4rem;
//     margin: 0 auto;
//     position: relative;
//     display: flex;
//     align-items: center;
//     justify-content: center;

//     video {
//         width: 100%;
//         height: 20rem;

//         &:focus {
//             outline: none;
//         }
//     }

//     .Activitypage-video-child-overlay {
//       width: 100%;
//       height: 100%;
//       position: absolute;
//       top: 0;
//       left: 0;
//     }
//     .Activitypage-overlay-hidden {
//       display: none;
//     }
//     .Activitypage-video-overlay {
//         height: 100%;
//         background-color: #fff;
//         opacity: 0.8;
//         border-radius: 1rem;

//     }
//     img {
//       position: absolute;
//       top: 8rem;
//       right: 42%;
//       width: 16%;
//     }
//   }
// }
// ================ END TABLET ==============

// // ================ LAPTOP ==============
// @media (min-width: 768px) and (max-width: 1085px) {
//   .Activitypage-main {
//     padding: 4rem 5rem 0 5rem;
//   }
// .Activitypage-video-parent-overlay {
//     width: 22rem;
//     margin: 0 auto;
//     position: relative;
//     display: flex;
//     align-items: center;
//     justify-content: center;

//     video {
//         width: 22rem;
//         height: 24rem;

//         &:focus {
//             outline: none;
//         }
//     }

//     .Activitypage-video-child-overlay {
//       width: 100%;
//       height: 100%;
//       position: absolute;
//       top: 0;
//       left: 0;
//     }
//     .Activitypage-overlay-hidden {
//       display: none;
//     }
//     .Activitypage-video-overlay {
//         height: 100%;
//         background-color:black;
//         opacity: 0.8;
//         border-radius: 1rem;

//     }
//     img {
//       position: absolute;
//       top: 10rem;
//       right: 9rem;
//     }
//   }
// }
// // ================ END LAPTOP ==============

// // ================ LARGE SCREENS ==============
// @media (min-width: 2000px) {
//   .Activitypage-video-parent-overlay {
//       width: 31.4rem;
//       margin: 0 auto;
//       position: relative;
//       display: flex;
//       align-items: center;
//       justify-content: center;

//       video {
//           width: 31.4rem;
//           height: 31.4rem;

//           &:focus {
//               outline: none;
//           }
//       }

//       .Activitypage-video-child-overlay {
//         width: 100%;
//         height: 100%;
//         position: absolute;
//         top: 0;
//         left: 0;
//       }
//       .Activitypage-overlay-hidden {
//         display: none;
//       }
//       .Activitypage-video-overlay {
//           height: 100%;
//           background-color: #fff;
//           opacity: 0.8;
//           border-radius: 1rem;

//       }
//       img {
//         position: absolute;
//         top: 13rem;
//         right: 13.7rem;
//       }
//     }

// }
// // ================ END LARGE SCREENS ==============
